import Footer from "../Footer";
import Navbar from "../Navbar";
import Outstationcars from "../Outststationcars";
function OutstationTaxi() {
    return (
        <div id='#Outstationtaxi'>
            <Outstationcars />
        </div>
    )
}
export default OutstationTaxi;