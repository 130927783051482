import Footer from "../Footer";
import Navbar from "../Navbar";
import Airportcars from "../Airportcars";
function Airporttaxi() {
    return (
        <div id='#Airporttaxi'>
            <Airportcars />
        </div>
    )
}
export default Airporttaxi;