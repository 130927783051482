export const Menuitems = [
    {
        title : "Home",
        url : "/",
        clsname : "nav-links",
        icon : ""

    },
   
    {
        title : "Airport Taxi",
        url : "/airporttaxi",
        clsname : "nav-links",
        icon : ""

    },
    {
        title : "Outstation Taxi",
        url : "/OutstationTaxi",
        clsname : "nav-links",
        icon : ""

    },
    {
        title : "Local Taxi",
        url : "/Localtaxi",
        clsname : "nav-links",
        icon : ""

    },
   
]