import Footer from "../Footer";
import Navbar from "../Navbar";
import Localcars from "../Localcars";
function LocalTaxi() {
    return (
        <div id='#Localtaxi'>
            <Localcars />
            
        </div>
    )
}
export default LocalTaxi;